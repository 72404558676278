import React from "react";
import Hero from '../Components/Hero/Hero'
import Popular from "../Components/Popular/Popular";
import Pleas from "../Components/Pleas/Pleas";


const Shop = () =>{
    return(
    <div>
        <Pleas/>
        <Hero/>
        <Popular/>
    </div>
    )
}
export default Shop