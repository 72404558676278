import React from "react";
import './DescriptionBox.css'

const DescriptionBox = () =>{
    return(
    <div className='descriotionbox'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-nav-box">Description</div>
            <div className="descriptionbox-nav-box fade">Reviews (122)</div>
        </div>
        <div className="descriptionbox-description">
            <p>HOLAAA QUE PEDO SOY LA DESCRIPTION ALV</p>
            <p>
                ALV VIEJILLO Y PURO DOPLE P ALV
            </p>
        </div>
    </div>
    )
}
export default DescriptionBox