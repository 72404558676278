import React from "react";
import CartItems from "../Components/CartItems/CartItems";

const Cart = () =>{
    return(
    <div>
        <CartItems/>
    </div>
    )
}
export default Cart